import React from 'react';
import { graphql } from 'gatsby';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import ButtonList from '../components/ButtonList';
import Card from '../components/Card';
import onlineHero from '../assets/img/telemed-top_bana.jpg';
import turnImage from '../assets/img/turn-image.jpg';
import appImg from '../assets/img/telemed-link_2.jpg';


const applicationLink = 'https://telemed-app.hirahata-clinic.or.jp/app_form.html?locate=shibuya';

const DentalArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Telemedicine = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const dentalArticles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="よくある質問">
      <Card>
      <H3>Q. 支払いはどのようにしますか？</H3>
      <P>
        診察終了後、メールでお支払いを促すメールを送らせていただきます。<br />
        メールの中のリンクをタップしていただくと、クレジットカードの情報の入力画面となりますので、そちらで情報を入力してください。<br />
        支払終了後、薬局に処方箋をFAX・郵送させていただきます
      </P>
      </Card>
      
      <Card>
        <H3>Q. 支払いの後の流れは？</H3>
        <P>
          支払いの通知が当院に届き次第、当院から薬局に処方箋をFAX・郵送いたします。<br />
          お申し込み時に指定していただいた薬局に薬を受け取りに行ってください。<br />
          （薬局で調剤料、薬代などの料金がかかります。）
        </P>
      </Card>
      
      <Card>
        <H3>Q. アビガンの処方はできますか？</H3>
        <P>
          できません。<br />
          アビガンは一般のクリニックなどでは手に入らない薬です。
        </P>
      </Card>
      
      <Card>
        <H3>Q. PCR検査はできますか？</H3>
        <P>
          基準に合致した方であれば、紹介状をお書きして、依頼することはできます。<br />
          基準に合致していない方については、大変申し訳ありませんが、依頼することはできません。
        </P>
      </Card>
      
      <Card>
        <H3>Q. 睡眠薬は処方できますか？</H3>
        <P>
          睡眠薬の種類によって、オンライン診療での処方が禁止されているものがあります。<br />
          それ以外についても、当院の通常の外来で処方を受けたことのない睡眠薬については、処方ができません。<br />
          また、薬を受け取る薬局も、いつもその睡眠薬を受け取っている薬局でなければ受け取ることができません。<br />
          薬を受け取れるかどうか、事前に薬局に確認するようにしてください。
        </P>
      </Card>
      
      <Card>
        <H3>Q. 処方箋の原本の取り扱いについて薬局に聞かれた</H3>
        <P>
          処方箋の原本は、後日薬局様に郵送させていただきます。
        </P>
      </Card>
      
      <Card>
        <H3>Q. クレジットカードは使えますか？</H3>
        <P>
          VISA、MASTER、JCB、AMEX、Dinersがご利用可能です。
        </P>
      </Card>
      
      <Card>
        <H3>Q. 料金は？</H3>
        <P>
          保険分の支払いの他に、「情報通信機器の運用に要する費用」として1,000円いただきます。<br />
          ３割負担の場合、新患の場合で1900円前後、再診の場合で1300円前後の支払いになることがほとんどです。<br />
          その他に、薬局で薬代などの費用が発生します。
        </P>
      </Card>
      
      <Card>
        <H3>Q. 何日分、処方してくれるの？</H3>
        <P>
          厚生労働省の指示により、基本的には７日分の処方となります。<br />
          生活習慣病などの慢性疾患で、経過がしっかり分かる場合には、もっと長期に処方することができます。<br />
          その場合でも、60日分が最長となります。
        </P>
      </Card>
      
      <Card>
        <H3>Q. 検索できない薬局を利用してもいい？</H3>
        <P>
          薬局検索機能は、少しでも利便性が上がるようにとおいているだけで、全ての調剤薬局を網羅するには程遠い状況となっております。<br />
          今後、さらに登録薬局を増やしていく予定ですが、ご利用いただきやすい薬局を使っていただいてまったく問題ありません。<br />
          よろしくお願いいたします。
        </P>
      </Card>
      
      <div css={`
          max-width:500px;
          margin:5em auto 3em;
          text-align:center;
      `}>
      <a href={applicationLink}>
        <Img src={appImg} css={'margin-bottom:2em;'}/>
        お申し込みはこちら
      </a>
      </div>
      
     < /SubLayout>
  );
};

export default Telemedicine;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/dental-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
